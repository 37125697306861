import { env } from '../env'

export const healthApiPath = (path: string) => {
  return new URL(path, env.HEALTH_API).toString()
}

export class RequestError<T> extends Error {
  constructor(private data: T) {
    super('Failed to retrieve data')
  }
}

export class MalformedResponseError<T> extends Error {
  constructor(private data: T) {
    super('Received malformed response data')
  }
}
