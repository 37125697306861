import { HealthRecord } from '../api/record'

export const statusValues = ['OK', 'LATE', 'ERROR', 'DISABLED'] as const

export type RecordStatus = typeof statusValues[number]

export const getStatusColor = (status: string): string => {
  switch (status) {
    case 'OK':
      return 'green'
    case 'LATE':
      return 'yellow'
    case 'ERROR':
      return 'orange'
    case 'DISABLED':
      return 'teal'
    default:
      return 'bluegray'
  }
}

export const getStatusCounts = (
  records: HealthRecord[]
): { status: RecordStatus | 'OTHER'; count: number }[] => {
  return [
    ...statusValues.map((status) => ({
      status,
      count: records.filter((x) => x.status === status).length,
    })),

    {
      status: 'OTHER',
      count: records.filter(
        (x) => !statusValues.includes(x.status as RecordStatus)
      ).length,
    },
  ]
}
