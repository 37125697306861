import React, { CSSProperties, memo } from 'react'
import { HealthRecord } from '../api/record'
import { getStatusCounts } from './HealthRecordStatus'
import { StatusPill } from './StatusPill'

interface Props {
  records: HealthRecord[]
  style?: CSSProperties
}

export const StatusSummaryStrip: React.FC<Props> = memo(
  ({ records, style }) => {
    const statusCounts = getStatusCounts(records)

    return (
      <div
        style={{
          display: 'flex',
          gap: '0.618em',
          ...style,
        }}
      >
        {statusCounts.map(({ status, count }) => (
          <StatusPill
            key={status}
            status={status}
            text={`${status}: ${count}`}
          />
        ))}
      </div>
    )
  }
)
