import { History } from 'history'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { CollectionMenu, fetchMenus, ProductMenu } from '../api/menu'

type MenuContextType = Readonly<{
  isLoading: boolean

  products: ProductMenu[]
  setSelectedProduct: (productId: string | null) => void
  selectedProduct: ProductMenu | null

  collections: CollectionMenu[]
  setSelectedCollection: (collectionId: string | null) => void
  selectedCollection: CollectionMenu | null
}>

const HealthMenuContext = createContext<MenuContextType>(undefined as any)

export const useHealthMenuContext = () => useContext(HealthMenuContext)

export const HealthMenuContextProvider: React.FC<{ history: History }> = ({
  children,
}) => {
  const menus = useQuery('menus', () => fetchMenus(), {
    initialData: [],
    initialDataUpdatedAt: 0,
    staleTime: Infinity,
  })

  const products = useMemo(() => menus.data!, [menus.data])

  const [selectedProductId, setSelectedProduct] = useState<string | null>(null)

  const selectedProduct = useMemo(
    () =>
      products.find((x) => x.id === selectedProductId) || products[0] || null,
    [products, selectedProductId]
  )

  const collections = useMemo(
    () => selectedProduct?.collectionMenus || [],
    [selectedProduct]
  )

  const [selectedCollectionId, setSelectedCollection] =
    useState<string | null>(null)

  const selectedCollection = useMemo(
    () =>
      collections?.find((x) => x.id === selectedCollectionId) ||
      collections[0] ||
      null,
    [collections, selectedCollectionId]
  )

  const value = {
    isLoading: menus.isLoading,

    products,
    selectedProduct,
    setSelectedProduct,

    collections,
    selectedCollection,
    setSelectedCollection,
  }

  return (
    <HealthMenuContext.Provider value={value}>
      {children}
    </HealthMenuContext.Provider>
  )
}
