import { MenuItem, PrimeIcons } from 'primereact/api'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { createRef, memo, useMemo } from 'react'
import { cssPhi } from '../util/math'

export const HealthTopbar: React.FC = memo(() => {
  const menu = createRef<Menu>()

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        label: 'Open Swagger',
        icon: PrimeIcons.EXTERNAL_LINK,
        command: () => {
          window.open('/swagger', '_blank')?.focus()
        },
      },
    ],
    []
  )

  return (
    <div
      style={{
        backgroundColor: 'var(--brand-primary)',
        color: 'var(--primary-color-text)',
        width: '100%',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: cssPhi(0.5),
        paddingRight: cssPhi(0.5),
      }}
    >
      <h3>CBUK Health</h3>

      <Menu model={menuItems} popup ref={menu} />

      <Button
        icon={PrimeIcons.ELLIPSIS_V}
        className="p-button-text"
        style={{
          color: 'var(--primary-color-text)',
        }}
        onClick={(e) => menu.current?.toggle(e)}
      />
    </div>
  )
})
