export const uniqueArray = <T>(arr: T[]) =>
  arr.filter((x, i) => arr.indexOf(x) === i)

export const toggleArrayValue = <T>(arr: T[], value: T): T[] => {
  if (arr.includes(value)) {
    return arr.filter((x) => x !== value)
  }

  return [...arr, value]
}
