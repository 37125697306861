import { Location } from 'history'
import qs from 'query-string'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useMountEffect } from './useMountEffect'

interface SearchParamCodec<T> {
  parse: (paramValue: string | null) => T
  stringify: (state: T) => string | null
}

export namespace SearchParamCodecs {
  export const nullableString: SearchParamCodec<string | null> = {
    parse: (x) => x,
    stringify: (x) => x,
  }

  export const boolean: SearchParamCodec<boolean> = {
    parse: (x) => (x === 'true' ? true : false),
    stringify: (x) => (x ? 'true' : null),
  }
}

/**
 * Binds the given value to the specified search param key.
 *
 * Loads the value from the URL on first render, with fallback as initialValue.
 * Updates the URL with the value whenever it changes.
 */
export const useBoundParamState = <T>(
  /** The name of the search param to bind to. */
  key: string,
  /** The `useState()` tuple for getting and setting the bound state. */
  [state, setState]: [T, (value: T) => void],
  /** Contains the methods for transforming between param value and state. */
  codec: SearchParamCodec<T>,
  /** Defines whether the initial value should come from the URL, or the bound state. */
  setStateOnMount: boolean = false
): void => {
  const history = useHistory()

  useMountEffect(() => {
    if (setStateOnMount) {
      setState(getParamValue<T>(history.location, codec, key))
    }
  })

  // Update the URL whenever the state changes
  useEffect(() => {
    // console.log(JSON.stringify(history.location, null, 2))
    history.replace(setParamValue<T>(history.location, codec, key, state))
    // console.log(JSON.stringify(history.location, null, 2))
  }, [history, codec, key, state])
}

const getParamValue = <T>(
  location: Location,
  { parse }: SearchParamCodec<T>,
  key: string
) => {
  const preValue = qs.parse(location.search)[key] ?? null
  const value = preValue instanceof Array ? preValue[0] : preValue

  return parse(value)
}

const setParamValue = <T>(
  location: Location,
  { stringify }: SearchParamCodec<T>,
  key: string,
  value: T
): Location => {
  const paramValue = stringify(value)

  const searchParams = {
    ...qs.parse(location.search),
    [key]: paramValue,
  }

  return {
    ...location,
    search: qs.stringify(searchParams, {
      skipNull: true,
    }),
  }
}
