export const processPcreRecursion = (
  pattern: string,
  depth: number
): string => {
  if (depth <= 0) {
    return ''
  }

  return pattern.replaceAll('?R', processPcreRecursion(pattern, depth - 1))
}
