import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Route, Switch, useHistory } from 'react-router'
import './App.css'
import { HealthHistoryDialog } from './components/HealthHistoryDialog'
import { ContextProvider } from './contexts/ContextProvider'
import DefaultLayout from './layouts/DefaultLayout'
import Dashboard from './pages/Dashboard'
import './primereact'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const history = useHistory()

  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider history={history}>
        <DefaultLayout>
          <Switch>
            <Route path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
          </Switch>
        </DefaultLayout>

        <HealthHistoryDialog />
        <ReactQueryDevtools initialIsOpen={true} />
      </ContextProvider>
    </QueryClientProvider>
  )
}

export default App
