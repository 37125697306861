import { DateTime } from 'luxon'
import { ProgressSpinner } from 'primereact/progressspinner'
import { memo, useMemo } from 'react'
import { useQuery } from 'react-query'
import { fetchProfileHistory } from '../api/record'
import { cssPhi } from '../util/math'
import { HealthRecordsTable } from './HealthRecordsTable'

const HISTORY_REFETCH_INTERVAL = 30000

export interface HealthHistoryProps {
  collectionId: string
  profile: string
}

export const HealthHistory: React.FC<HealthHistoryProps> = memo(
  ({ collectionId, profile }) => {
    const query = useQuery(
      [
        'health-history',
        {
          collectionId,
          profile,
        },
      ],
      () => fetchProfileHistory(collectionId, profile),
      {
        enabled: !!collectionId && !!profile,
        initialData: [],
        refetchInterval: HISTORY_REFETCH_INTERVAL,
        refetchIntervalInBackground: true,
      }
    )

    const lastUpdatedString = useMemo(
      () =>
        DateTime.fromMillis(query.dataUpdatedAt).toLocal().toLocaleString({
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }),
      [query.dataUpdatedAt]
    )

    return (
      <>
        <p
          style={{
            marginBottom: cssPhi(0),
          }}
        >
          Last updated: {lastUpdatedString}
        </p>

        {query.isLoading ? (
          <ProgressSpinner />
        ) : (
          <div
            style={{
              maxWidth: '1000px',
            }}
          >
            <HealthRecordsTable
              collectionId={collectionId}
              records={query.data!}
              columns={['expand', 'timestamp', 'status', 'detail']}
            />
          </div>
        )}
      </>
    )
  }
)
