import React, { memo, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ProductMenu } from '../api/menu'
import { useHealthMenuContext } from '../contexts/HealthMenuContext'
import { toggleArrayValue } from '../util/array'
import { cssPhi } from '../util/math'

export const Sidebar: React.FC = memo(() => {
  const history = useHistory()
  const {
    products,
    selectedProduct,
    setSelectedProduct,
    selectedCollection,
    setSelectedCollection,
  } = useHealthMenuContext()

  const [expandedProducts, setExpandedProducts] = useState<string[]>([])

  useEffect(() => {
    if (selectedProduct && !expandedProducts.includes(selectedProduct.id)) {
      setExpandedProducts([...expandedProducts, selectedProduct.id])
    }
  }, [selectedProduct, expandedProducts])

  const toggleProductExpanded = useCallback(
    (productId: string) => {
      setExpandedProducts(toggleArrayValue(expandedProducts, productId))
    },
    [expandedProducts]
  )

  const isProductExpanded = useCallback(
    (product: ProductMenu) => expandedProducts.includes(product.id),
    [expandedProducts]
  )

  return (
    <div
      className="p-px-2 p-py-3"
      style={{
        backgroundColor: 'var(--surface-100)',
        height: '100%',
      }}
    >
      {products.map((product) => (
        <div
          key={product.id}
          style={{
            marginBottom: cssPhi(1),
          }}
        >
          <div
            style={{
              margin: cssPhi(-1),
              display: 'flex',
              fontWeight: 'bold',
              fontSize: '0.8rem',
              cursor: 'pointer',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
            onClick={() => toggleProductExpanded(product.id)}
          >
            <span>{product.label}</span>
            <i
              className={`pi ${
                isProductExpanded(product) ? 'pi-angle-down' : 'pi-angle-right'
              }`}
            />
          </div>
          {isProductExpanded(product) && (
            <ul
              style={{
                listStyle: 'none',
                padding: 0,
              }}
            >
              {product.collectionMenus.map((collection) => (
                <li
                  key={collection.id}
                  onClick={() => {
                    setSelectedProduct(product.id)
                    setSelectedCollection(collection.id)
                    history.push('/dashboard')
                  }}
                  className="p-px-3 p-py-2 p-my-1"
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      collection.id === selectedCollection?.id
                        ? 'var(--bluegray-100)'
                        : 'initial',
                    fontWeight:
                      collection.id === selectedCollection?.id ? 600 : 'normal',
                    borderRadius: '5px',
                  }}
                >
                  {collection.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  )
})
