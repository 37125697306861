import memoize from 'lodash/memoize'
import { tryCatch } from './functions'
import { processPcreRecursion } from './regexp'

// https://stackoverflow.com/a/63660736
const JSON_PATTERN = '\\{(?:[^{}]|(?R))*\\}'
const JSON_DEPTH = 10

export interface SeparatedStringJson {
  orig: string
  textOnly: string
  data?: unknown
}

const re = new RegExp(processPcreRecursion(JSON_PATTERN, JSON_DEPTH))

export const separateStringJson = memoize(
  (str: string): SeparatedStringJson => {
    const match = re.exec(str)

    if (!match) {
      return { orig: str, textOnly: str }
    }

    const jsonString = match[0]
    const data = tryCatch(() => JSON.parse(jsonString))

    if (!data) {
      return { orig: str, textOnly: str }
    }

    const textOnly = str.replace(jsonString, '').trim()

    return { orig: str, textOnly, data }
  }
)
