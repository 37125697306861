import fetch from 'isomorphic-unfetch'
import { DateTime } from 'luxon'
import * as z from 'zod'
import { SeparatedStringJson, separateStringJson } from '../util/strings'
import { healthApiPath, MalformedResponseError, RequestError } from './client'

const healthRecord = z.object({
  id: z.string(),
  reportedAt: z.string().refine((x) => dateStringRefinement(x)),
  nextExpectedAt: z
    .string()
    .refine((x) => dateStringRefinement(x))
    .optional(),
  status: z.string(),
  detail: z.string(),
  profile: z.string(),
})

type RawHealthRecord = z.infer<typeof healthRecord>

interface ModifiedProps {
  detail: SeparatedStringJson
  reportedAt: DateTime
  nextExpectedAt?: DateTime
}

export type HealthRecord = Omit<RawHealthRecord, keyof ModifiedProps> &
  ModifiedProps

export const fetchRecordsByCollection = async (
  collectionId: string
): Promise<HealthRecord[]> => {
  const response = await fetch(
    healthApiPath(`HealthRecords/Current?collectionId=${collectionId}`)
  )

  if (!response.ok) {
    throw new RequestError({ collectionId })
  }

  const rawData: unknown = await response.json()

  const parsed = z.array(healthRecord).safeParse(rawData)

  if (!parsed.success) {
    throw new MalformedResponseError({ collectionId })
  }

  const processed = parsed.data.map((x) => processRawHealthRecord(x))

  return processed
}

export const fetchProfileHistory = async (
  collectionId: string,
  profile: string
): Promise<HealthRecord[]> => {
  const response = await fetch(
    healthApiPath(
      `HealthRecords/History?collectionId=${collectionId}&profile=${profile}`
    )
  )

  if (!response.ok) {
    throw new RequestError({
      profile,
      collectionId,
    })
  }

  const rawData: unknown = await response.json()

  const parsed = z.array(healthRecord).safeParse(rawData)

  if (!parsed.success) {
    throw new MalformedResponseError({
      profile,
      collectionId,
    })
  }

  const processed = parsed.data.map((x) => processRawHealthRecord(x))

  return processed
}

const processRawHealthRecord = (x: RawHealthRecord): HealthRecord => {
  return {
    ...x,
    detail: separateStringJson(x.detail),
    reportedAt: DateTime.fromISO(x.reportedAt),
    nextExpectedAt: x.nextExpectedAt
      ? DateTime.fromISO(x.nextExpectedAt)
      : void 0,
  }
}

const dateStringRefinement = (dateString: string) =>
  DateTime.fromISO(dateString).isValid
