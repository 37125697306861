import React, { memo } from 'react'
import { cssPhi } from '../util/math'
import { Sidebar } from './Sidebar'
import { HealthTopbar } from './Topbar'

const DefaultLayout: React.FC = memo(({ children }) => {
  return (
    <>
      <HealthTopbar />

      <div
        style={{
          position: 'absolute',
          top: '3rem',
          right: '0',
          bottom: '0',
          left: '0',
          display: 'flex',
        }}
      >
        <div style={{ width: '250px' }}>
          <Sidebar />
        </div>

        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            padding: cssPhi(0),
          }}
        >
          {children}
        </div>
      </div>
    </>
  )
})

export default DefaultLayout
