import { DateTime } from 'luxon'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchRecordsByCollection, HealthRecord } from '../api/record'
import { HealthRecordsTable } from '../components/HealthRecordsTable'
import { StatusSummaryStrip } from '../components/StatusSummaryStrip'
import { useHealthMenuContext } from '../contexts/HealthMenuContext'
import { formatLongDateTime } from '../util/datetime'
import {
  SearchParamCodecs,
  useBoundParamState,
} from '../util/hooks/useSearchParam'
import { cssPhi } from '../util/math'

const REFETCH_INTERVAL = 30000

const Dashboard: React.FC = memo(() => {
  const {
    selectedProduct,
    setSelectedProduct,
    selectedCollection,
    setSelectedCollection,
  } = useHealthMenuContext()

  useBoundParamState(
    'product',
    [
      useMemo(() => selectedProduct?.id ?? null, [selectedProduct]),
      setSelectedProduct,
    ],
    SearchParamCodecs.nullableString,
    true
  )

  useBoundParamState(
    'collection',
    [
      useMemo(() => selectedCollection?.id ?? null, [selectedCollection]),
      setSelectedCollection,
    ],
    SearchParamCodecs.nullableString,
    true
  )

  const collectionId = useMemo(
    () => selectedCollection?.id,
    [selectedCollection]
  )

  const query = useQuery(
    ['records', { collectionId }],
    () => fetchRecordsByCollection(collectionId!),
    {
      enabled: !!collectionId,
      initialData: [],
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  )

  const lastUpdatedString = useMemo(
    () => formatLongDateTime(DateTime.fromMillis(query.dataUpdatedAt)),
    [query.dataUpdatedAt]
  )

  const [tableRows, setTableRows] = useState<HealthRecord[]>([])

  const onTableRowsChanged = useCallback(
    (records: HealthRecord[]) => {
      setTableRows(records)
    },
    [setTableRows]
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingBottom: cssPhi(-1),
          borderBottom: '1px solid #ddd',
          marginBottom: cssPhi(0),
        }}
      >
        <h1>
          {selectedProduct?.label}: {selectedCollection?.label}
        </h1>
        <p>Last updated: {lastUpdatedString}</p>
      </div>

      <StatusSummaryStrip
        records={tableRows}
        style={{
          marginBottom: cssPhi(0),
          marginLeft: cssPhi(-1),
        }}
      />

      <HealthRecordsTable
        collectionId={selectedCollection?.id ?? null}
        records={query.data!}
        columns={[
          'expand',
          'timestamp',
          'status',
          'profile',
          'detail',
          'due',
          'buttons',
        ]}
        onTableRowsChanged={onTableRowsChanged}
      />
    </>
  )
})

export default Dashboard
