import React, { createContext, useCallback, useContext, useState } from 'react'

export type DialogContext<T, U = void> = Readonly<{
  isVisible: boolean
  props: T | null
  onHide: (value: U) => void

  show: (props: T) => Promise<U>
}>

export const makeDialogContext = <T, U = void>() => {
  const Context = createContext<DialogContext<T, U>>(undefined as any)

  const DialogProvider: React.FC = ({ children }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [props, setProps] = useState<T | null>(null)

    const [resolveShow, setResolveShow] =
      useState<((value: U) => void) | null>(null)

    const onHide = useCallback(
      (value: U) => {
        setIsVisible(false)
        setProps(null)

        resolveShow?.(value)
      },
      [resolveShow]
    )

    const show = useCallback(async (props: T) => {
      setProps(props)
      setIsVisible(true)

      return new Promise<U>((resolve) => setResolveShow(() => resolve))
    }, [])

    const value: DialogContext<T, U> = {
      isVisible,
      props,
      onHide,
      show,
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
  }

  const useDialog = () => useContext(Context)

  return {
    DialogProvider,
    useDialog,
  }
}
