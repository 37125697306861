import { DateTime } from 'luxon'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/components/column/Column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { HealthRecord } from '../api/record'
import { useHealthHistoryDialog } from '../contexts/HealthHistoryDialogContext'
import { formatRelativeDateTime, formatShortDateTime } from '../util/datetime'
import { cssPhi } from '../util/math'
import { statusValues } from './HealthRecordStatus'
import { StatusPill } from './StatusPill'

type HealthRecordsTableColumn =
  | 'expand'
  | 'timestamp'
  | 'status'
  | 'profile'
  | 'detail'
  | 'due'
  | 'buttons'

const emptyFilters = {
  timestamp: null as Date[] | null,
  nextTimestamp: null as Date[] | null,
  status: [] as string[],
  profile: '' as string,
  detailData: null as boolean | null,
  detail: '' as string,
}

type Filters = typeof emptyFilters

interface HealthRecordsTableProps {
  collectionId: string | null
  records: HealthRecord[]
  columns: HealthRecordsTableColumn[]
  hideFilters?: boolean
  onTableRowsChanged?: (rows: HealthRecord[]) => void
}

const initialSort = {
  sortField: 'reportedAt' as keyof HealthRecord,
  sortOrder: -1 as -1 | 1,
}

type HealthRecordsSort = typeof initialSort

export const HealthRecordsTable: React.FC<HealthRecordsTableProps> = memo(
  ({ collectionId, records, columns, hideFilters, onTableRowsChanged }) => {
    const healthHistoryDialog = useHealthHistoryDialog()

    const [filters, _setFilters] = useState<Filters>(emptyFilters)

    const setFilter = useCallback(
      <K extends keyof Filters>(name: K, value: Filters[K]) => {
        _setFilters({
          ...filters,
          [name]: value,
        })
      },
      [filters]
    )

    const filtersActive = useMemo(
      () =>
        Object.entries(filters).some(
          ([key, value]) => (emptyFilters as Record<string, any>)[key] !== value
        ),
      [filters]
    )

    const [sort, setSort] = useState<HealthRecordsSort>(initialSort)

    const [expandedRows, setExpandedRows] = useState<HealthRecord[]>([])

    const resetFilters = useCallback(() => {
      _setFilters(emptyFilters)
    }, [_setFilters])

    // Reset filters and sort when collection changes
    useEffect(() => {
      resetFilters()
      setSort(initialSort)
    }, [resetFilters, setSort])

    const [tableRows, setTableRows] = useState<HealthRecord[]>([])

    // Update table rows when data, filter or sort changes
    useEffect(() => {
      setTableRows(
        records
          .filter((x) =>
            filters.timestamp
              ? x.reportedAt > DateTime.fromJSDate(filters.timestamp[0])
                ? filters.timestamp[1]
                  ? x.reportedAt < DateTime.fromJSDate(filters.timestamp[1])
                  : true
                : false
              : true
          )
          .filter((x) =>
            filters.nextTimestamp
              ? x.nextExpectedAt &&
                x.nextExpectedAt > DateTime.fromJSDate(filters.nextTimestamp[0])
                ? filters.nextTimestamp[1]
                  ? x.nextExpectedAt <
                    DateTime.fromJSDate(filters.nextTimestamp[1])
                  : true
                : false
              : true
          )
          .filter((x) =>
            filters.status.length > 0 ? filters.status.includes(x.status) : true
          )
          .filter((x) =>
            filters.profile.length > 0
              ? filters.profile
                  .toLowerCase()
                  .split(' ')
                  .filter((x) => !!x)
                  .every((searchTerm) =>
                    x.profile
                      .toLowerCase()
                      .split(' ')
                      .some((term) => term.includes(searchTerm))
                  )
              : true
          )
          .filter((x) =>
            filters.detailData !== null
              ? !!x.detail.data === filters.detailData
              : true
          )
          .filter((x) =>
            filters.detail.length > 0
              ? filters.detail
                  .toLowerCase()
                  .split(' ')
                  .filter((x) => !!x)
                  .every((searchTerm) =>
                    x.detail.orig
                      .toLowerCase()
                      .split(' ')
                      .some((term) => term.includes(searchTerm))
                  )
              : true
          )
          .sort((a, b) => {
            const first = a[sort.sortField] ?? null
            const second = b[sort.sortField] ?? null

            if (first === null && second === null) {
              return 0
            } else if (first === null) {
              return -1
            } else if (second === null) {
              return 1
            }

            const current = first > second
            const desired = sort.sortOrder === 1
            return current === desired ? 1 : -1
          })
      )
    }, [records, filters, sort, onTableRowsChanged])

    useEffect(() => {
      onTableRowsChanged?.(tableRows)
    }, [onTableRowsChanged, tableRows])

    // Reset expanded rows when filters or sort changes
    useEffect(() => {
      setExpandedRows([])
    }, [filters, sort])

    const ResetFiltersButton = useMemo(
      () => (
        <Button
          title="Reset Filters"
          icon="pi pi-filter-slash"
          disabled={!filtersActive}
          className={`p-button-rounded p-button-text ${
            filtersActive ? '' : 'p-button-plain'
          }`}
          onClick={() => resetFilters()}
        />
      ),
      [filtersActive, resetFilters]
    )

    const TimestampFilter = useMemo(
      () => (
        <Calendar
          value={filters.timestamp || void 0}
          onChange={(e: any) => setFilter('timestamp', e.value)}
          selectionMode="range"
          readOnlyInput
          showButtonBar
          style={{
            width: '100%',
          }}
        />
      ),
      [filters.timestamp, setFilter]
    )

    const StatusFilter = useMemo(
      () => (
        <MultiSelect
          value={filters.status}
          onChange={(e) => setFilter('status', e.value)}
          options={[...statusValues]}
          placeholder="​"
          className="p-column-filter"
          itemTemplate={(option) => <StatusPill status={option} />}
          maxSelectedLabels={Infinity}
          selectedItemTemplate={(option) =>
            option ? (
              <StatusPill
                status={option}
                text=""
                style={{
                  marginRight: cssPhi(-4),
                }}
              />
            ) : null
          }
          style={{ width: '100%' }}
        />
      ),
      [filters.status, setFilter]
    )

    const ProfileFilter = useMemo(
      () => (
        <InputText
          type="search"
          value={filters.profile}
          onChange={(e) => setFilter('profile', e.target.value)}
          style={{
            width: '100%',
          }}
        />
      ),
      [filters.profile, setFilter]
    )

    const DetailFilter = useMemo(
      () => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'stretch',
          }}
        >
          <Button
            title={
              filters.detailData !== null
                ? filters.detailData
                  ? 'Filtering for records with data'
                  : 'Filtering for records without data'
                : 'Filter for records with or without data'
            }
            icon="pi pi-file"
            className={
              filters.detailData !== null
                ? filters.detailData
                  ? 'p-button-outlined'
                  : 'p-button-outlined p-button-danger'
                : 'p-button-plain p-button-text'
            }
            onClick={() =>
              setFilter(
                'detailData',
                filters.detailData !== null
                  ? filters.detailData
                    ? false
                    : null
                  : true
              )
            }
            style={{
              marginRight: cssPhi(-1),
            }}
          />
          <InputText
            type="search"
            value={filters.detail}
            onChange={(e) => setFilter('detail', e.target.value)}
            style={{
              flexGrow: 1,
            }}
          />
        </div>
      ),
      [filters.detailData, filters.detail, setFilter]
    )

    const DueFilter = useMemo(
      () => (
        <Calendar
          value={filters.nextTimestamp || void 0}
          onChange={(e: any) => setFilter('nextTimestamp', e.value || null)}
          selectionMode="range"
          readOnlyInput
          showButtonBar
          style={{
            width: '100%',
          }}
        />
      ),
      [filters.nextTimestamp, setFilter]
    )

    const TimestampBody = useCallback(
      ({ reportedAt }: HealthRecord) => (
        <span title={formatRelativeDateTime(reportedAt)}>
          {formatShortDateTime(reportedAt)}
        </span>
      ),
      []
    )

    const NextTimestampBody = useCallback(
      ({ nextExpectedAt }: HealthRecord) => (
        <>
          {nextExpectedAt && (
            <span title={formatRelativeDateTime(nextExpectedAt) ?? ''}>
              {formatShortDateTime(nextExpectedAt)}
            </span>
          )}
        </>
      ),
      []
    )

    const StatusBody = useCallback(
      ({ status }: HealthRecord) => (
        <div
          title={status}
          style={{
            height: '100%',
            display: 'flex',
            alignContent: 'baseline',
          }}
        >
          <StatusPill status={status} />
        </div>
      ),
      []
    )

    const ProfileBody = useCallback(
      ({ profile }: HealthRecord) => (
        <p
          title={profile}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {profile}
        </p>
      ),
      []
    )

    const DetailBody = useCallback(
      ({ detail }: HealthRecord) => (
        <p
          title={detail.orig}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {detail.data ? (
            <i
              className="pi pi-file p-mr-1"
              title="This record has data"
              style={{
                verticalAlign: 'middle',
              }}
            />
          ) : null}
          {detail.orig}
        </p>
      ),
      []
    )

    const ButtonsBody = useCallback(
      ({ profile }: HealthRecord) => {
        const onClick = () => {
          collectionId &&
            healthHistoryDialog.show({
              collectionId,
              profile,
            })
        }

        return (
          <Button
            className="p-button-rounded p-button-text"
            icon="pi pi-clock"
            title="View Profile History"
            onClick={onClick}
          />
        )
      },
      [collectionId, healthHistoryDialog]
    )

    const RowExpansionTemplate: React.FC<HealthRecord> = ({
      profile,
      detail,
    }) => (
      <>
        {[
          { label: 'Profile', value: profile },
          { label: 'Detail', value: detail.textOnly },
        ].map(({ label, value }) =>
          value ? (
            <div
              key={label}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: cssPhi(-1),
                gap: cssPhi(-2),
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{label}:</span>
              <span>{value}</span>
            </div>
          ) : null
        )}

        {detail.data && (
          <div>
            <p
              style={{
                marginBottom: cssPhi(-2),
              }}
            >
              <strong>Data:</strong>
            </p>
            <SyntaxHighlighter language="json">
              {JSON.stringify(detail.data, null, 2)}
            </SyntaxHighlighter>
          </div>
        )}
      </>
    )

    const onRowToggle = ({ data }: { data: HealthRecord[] }) => {
      // PrimeReact data table provides all the expanded rows
      // It's not possible to just get the selected one
      // So here's some magic sticky tape
      setExpandedRows(
        data.filter((x) => !expandedRows.some((y) => y.id === x.id))
      )
    }

    return (
      <>
        <DataTable
          className="p-datatable-xs"
          value={tableRows}
          emptyMessage={() => <span>There are no records to show</span>}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          rowExpansionTemplate={RowExpansionTemplate}
          rows={10}
          rowsPerPageOptions={[10, 20, 30, 40]}
          paginator
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          sortField={sort.sortField}
          sortOrder={sort.sortOrder}
          onSort={(e: any) => {
            // onSort is called on DataTable even when the column is not sortable
            // So here's some magic sticky tape
            if (
              Array<keyof HealthRecord>(
                'reportedAt',
                'nextExpectedAt',
                'profile'
              ).includes(e.sortField)
            ) {
              setSort(e)
            }
          }}
        >
          {columns.includes('expand') && (
            <Column
              expander
              style={{ width: '3em' }}
              filter={!hideFilters}
              filterElement={ResetFiltersButton}
            />
          )}
          {columns.includes('timestamp') && (
            <Column
              field="reportedAt"
              header="Timestamp"
              style={{ width: cssPhi(4.5) }}
              body={TimestampBody}
              filter={!hideFilters}
              filterElement={TimestampFilter}
              sortable
            />
          )}
          {columns.includes('status') && (
            <Column
              field="status"
              header="Status"
              style={{ width: cssPhi(4) }}
              filter={!hideFilters}
              filterElement={StatusFilter}
              body={StatusBody}
            />
          )}
          {columns.includes('profile') && (
            <Column
              field="profile"
              header="Profile"
              style={{ width: cssPhi(5) }}
              sortable
              filter={!hideFilters}
              filterElement={ProfileFilter}
              body={ProfileBody}
            />
          )}
          {columns.includes('detail') && (
            <Column
              field="detail"
              header="Detail"
              filter={!hideFilters}
              filterElement={DetailFilter}
              body={DetailBody}
            />
          )}
          {columns.includes('due') && (
            <Column
              field="nextExpectedAt"
              header="Due"
              style={{ width: cssPhi(4.5) }}
              body={NextTimestampBody}
              filter={!hideFilters}
              filterElement={DueFilter}
              sortable
            />
          )}
          {columns.includes('buttons') && (
            <Column style={{ width: cssPhi(2.25) }} body={ButtonsBody} />
          )}
        </DataTable>
      </>
    )
  }
)
