import { DateTime } from 'luxon'

export const formatLongDateTime = (date: DateTime): string => {
  return date.toLocal().toLocaleString({
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const formatShortDateTime = (date: DateTime): string => {
  return date.toLocal().toLocaleString({
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
}

export const formatRelativeDateTime = (date: DateTime): string => {
  return date.toLocal().toRelative() ?? ''
}
