import { CSSProperties, memo, useMemo } from 'react'
import { cssPhi } from '../util/math'
import { getStatusColor } from './HealthRecordStatus'

interface StatusPillProps {
  status: string
  text?: string
  style?: CSSProperties
}

export const StatusPill: React.FC<StatusPillProps> = memo(
  ({ status, text, style }) => {
    const displayText = useMemo(() => text ?? status, [text, status])

    return (
      <div
        style={{
          display: 'inline-block',
          maxWidth: '100%',
          minWidth: cssPhi(-2),
          minHeight: cssPhi(0),
          backgroundColor: `var(--${getStatusColor(status)}-500)`,
          color: 'var(--primary-color-text)',
          borderRadius: '5px',
          ...style,
        }}
      >
        {displayText && (
          <p
            style={{
              padding: `${cssPhi(-2)} ${cssPhi(-1)}`,
              fontWeight: 700,
              fontSize: cssPhi(-0.5),
              letterSpacing: cssPhi(-5),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {displayText}
          </p>
        )}
      </div>
    )
  }
)
