import fetch from 'isomorphic-unfetch'
import * as z from 'zod'
import { healthApiPath } from './client'

const collectionMenu = z.object({
  id: z.string(),
  label: z.string(),
})

export type CollectionMenu = z.infer<typeof collectionMenu>

const productMenu = z.object({
  id: z.string(),
  label: z.string(),
  collectionMenus: z.array(collectionMenu),
})

export type ProductMenu = z.infer<typeof productMenu>

export const fetchMenus = async (): Promise<ProductMenu[]> => {
  const response = await fetch(healthApiPath('Menu/Available'))

  if (!response.ok) {
    throw new Error('Failed to retrieve application menus')
  }

  const rawData: unknown = await response.json()

  const parsed = z.array(productMenu).safeParse(rawData)

  if (!parsed.success) {
    throw new Error('Received malformed response for application menus')
  }

  return parsed.data
}
