import { Dialog } from 'primereact/dialog'
import { memo } from 'react'
import { useHealthHistoryDialog } from '../contexts/HealthHistoryDialogContext'
import { HealthHistory } from './HealthHistory'

export const HealthHistoryDialog = memo(() => {
  const { isVisible, props, onHide } = useHealthHistoryDialog()

  return (
    <Dialog
      onHide={onHide}
      visible={isVisible}
      header={`History for "${props?.profile}"`}
      style={{
        maxWidth: '800px',
      }}
    >
      {props && <HealthHistory {...props} />}
    </Dialog>
  )
})
