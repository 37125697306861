import React from 'react'
import { HealthHistoryDialogProvider } from './HealthHistoryDialogContext'
import { HealthMenuContextProvider } from './HealthMenuContext'

type Props = React.ComponentProps<typeof HealthMenuContextProvider>

export const ContextProvider: React.FC<Props> = ({ history, children }) => {
  return (
    <HealthMenuContextProvider history={history}>
      <HealthHistoryDialogProvider>{children}</HealthHistoryDialogProvider>
    </HealthMenuContextProvider>
  )
}
